.nonHomepage {
    margin: 70px 0 0 0;
    padding-top: 120px;
    padding-bottom: 100px;
}

.pageHeading{
    margin: 0 50px ;
}

.pageName {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
}

.pageSubname {
    font-size: 50px;
    font-weight: 600;
    color: #A20808;
}

.bioSection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    margin: 50px 50px 0 50px;
    color: #645D73;;
    
}
.bioHeader{
    grid-column: 1/span 2;
    grid-row: 1 / span 1;
    justify-self: center;
    font-size: 40px;
    font-weight: 400;
    color: black;
}

.profileImgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

}

.profilePhoto {
    display: flex;
    justify-content: center;
    border-radius: 5px;
    width: 500px;
    margin-bottom: 20px;
}

.modalContainer > div {
    margin-top: 10px;
}

.Modal{
    margin: auto;
    margin-top: 150px;
    width: 700px;
    height: 700px;
    background-color: #F0F0F2;
    overflow-x: scroll;
    overflow-y:visible
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.677);
  }

  .xIcon{
    width: 100%;
    text-align: right;
    color: black;
    font-size: 20px;
    border: none;
    padding-right: 20px;
    padding-top: 20px;
    cursor: pointer;
  }

.button, .closeButton {
    background-color: #A20808;
    color: white;
    border: none;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
}

.button:hover, .closeButton:hover {
    background-color: rgb(49, 48, 48);
    
}

.closeButton {
    margin: 0 0 20px 280px;
}

.modalHeader{
    width: 100%;
    color: #A20808;
    text-align: center;
    font-size: 40px;
}

.ownerBio {
    grid-column: 2 /span 1;
    grid-row: 2 / span 1;
}

.ownerBio, .coOwnerBio {
    margin: 50px;
}

.profileImgContainer > h3 {
    color: #645D73;;
}

.ownerBio > h4, .coOwnerBio > h4 {
    font-size: 18px;
    margin-top: 40px;
}

.ownerBio p, .coOwnerBio p {
    font-size: 16px;
    margin-top: 10px;
    margin-left: 20px;
    line-height: 20px;
    font-weight: 300;
}

.mission {
    font-size: 40px;
    font-weight: 600;
}

.missionContainer {
    text-align: center;
    margin: 50px 0 50px 0;
    padding: 40px 70px;
    font-size: 26px;
    font-weight: 300;
    color: #A60303;
    line-height: 36px;
    width: 100%;
    box-sizing: border-box;
    background-color: #F0F0F2;;
}

.missionParagraph{
    color: black;
}

@media only screen and (max-width: 1200px)  {
    .profilePhoto {
        width: 400px;
    }

}

@media only screen and (max-width: 950px)  {
    .pageName {
        font-size: 25px;
    }
    
    .pageSubname {
        font-size: 40px;
    }

    .bioSection {
        grid-template-columns: 1fr;
    }

    .bioHeader{
        grid-column: 1/span 1;
        grid-row: 1 / span 1;
        justify-self: center;
        font-size: 35px;
    }
    
    .profileImgContainer:nth-of-type(1) {
        grid-row: 2 /span 1;
    }

    .Modal{
        width: 500px;
        height: 550px;
    }

    .modalHeader {
        font-size: 25px;
    }

    .ownerBio, .coOwnerBio {
        margin: 20px 15px;
    }

    .ownerBio p, .coOwnerBio p {
        font-size: 14px;
        margin-top: 10px;
        margin-left: 0px;
    }

    .mission {
        font-size: 35px;
    }
    
    .missionContainer {
        padding: 40px 50px;
        font-size: 22px;
        line-height: 32px;
    }
    
}

@media only screen and (max-width: 500px)  {

    .nonHomepage {
        margin: 40px 0 0 0;
        padding-bottom: 60px;
    }

    .pageHeading{
        margin: 0 20px ;
        text-align: center;
    }

    .pageName {
        font-size: 20px;
    }
    
    .pageSubname {
        font-size: 35px;
    }

    .profilePhoto {
        width: 200px;
    }

    .profileImgContainer > h3 {
        font-size: 25px;
    }

    .bioSection {
        margin: 40px 15px 0 15px;
    }

    .bioHeader{
        grid-column: 1/span 1;
        grid-row: 1 / span 1;
        justify-self: center;
        font-size: 30px;
    }

    .Modal{
        width: 200px;
        height: 450px;
        text-align: center;
    }

    .mission {
        font-size: 30px;
    }
    
    .missionContainer {
        margin: 40px 0;
        padding: 30px 30px;
        font-size: 18px;
    }
    
}