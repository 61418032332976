@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Poppins&family=Raleway:ital,wght@0,500;1,400&display=swap');

.appHeader {
    font-family: 'Raleway', sans-serif;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto 70px;
    background-image:
        linear-gradient(
            rgba(22, 22, 22, 0.5), 
            rgba(0, 0, 0, 0.5)
            ), 
        url("../assets/hero1.jpeg");
    height:600px;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: cover;
    position: relative;
    font-style: italic;
}

.HamburgerMenuContainer {
    grid-column: 5 /span 1;
    grid-row: 1 / span 1;
    height: 40px;
    margin: 10px 10px 0 0;
    justify-self: center;
    position: absolute;
    z-index: 10;
}

.imgContainer {
    display: grid;
    grid-row: 1 / span 1;
    grid-column: 3 / span 1;
    margin-bottom: 50px;
    
}

.logoLink {
    justify-self: center; 
}

.logo {
    width: 350px;
    margin-top: 30px; 
    padding-top: 24px;
    background-color: white;
    shape-outside: circle();
    clip-path: circle();   
    
}

.slogan {
    color: white;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-shadow: 2px 2px 3px black;
    width: 700px;
}

.icons {
    width: 30px;
    padding-top: 10px;
    padding-right: 20px;
    justify-self: flex-end;
    grid-column: 5 / span 1;
    
}

.icons > li {
    list-style: none;
    margin-bottom: 16px;
}

.phone , .emailIcon, .fbIcon {
    width: 30px;
}

.linksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 24px;
    box-sizing: border-box;
    grid-column: 1 / span 5;
    grid-row: 2 / span 1;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.778);
}
.linksContainer :hover {
    background-color: rgb(49, 48, 48);
}

.icons > li > a {
    padding: 14px 34px;
    color: white;
    text-decoration: none;
    font-style: normal;
}

.navLine {
    height: 35px;
    margin: 0;
}

.licensedContainer{
    grid-column: 1/ span 1;
    grid-row: 1/span 1;
    
}

.licensedIcon{
    width: 150px;
    position: absolute;
    padding: 10px;
}

@media only screen and (max-width: 1200px)  {
    .linksContainer {
        font-size: 20px;
    }
}

@media only screen and (max-width: 950px) {
    .appHeader {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: auto 70px;
        height:440px;
    }

    .imgContainer {
        grid-row: 1 / span 1;
        grid-column: 3 / span 1;
        display: grid;
        grid-template-rows: 325px auto;
        margin-bottom: -40px;
    }

    .logo {
        width: 250px;
        margin-top: 30px; 
        padding-top: 17px;
        background-color: white;
        shape-outside: circle();
        clip-path: circle();   
        
    }
    
    .slogan {
        font-size: 20px;
        width: 100%;
    }

    .licensedIcon{
        width: 125px;
    }

    .licensedContainer{
        grid-column: 1/ span 1;
        grid-row: 1/span 1;
        padding: 0px;
    }

    .linksContainer {
        flex-direction: column;
        align-items: center;
        justify-content: start;
        box-sizing: border-box;
        width: 100%;
    }

   .appHeader li > a {
        font-size: 1px;
        display: none;
        width: 1px;
    }
    
    .appHeader .navLine {
       display: none;

    }

    .icons {
        position: absolute;
        width: 90px;
        padding: 0;
        justify-self: flex-end;
        align-self: center;
        grid-column: 5 / span 1;
        grid-row: 2;
        display: flex;
        box-sizing: border-box;
    }
    
    .icons > li {
        margin-bottom: 0;
        margin-right: 10px;
    }
    
    .phone, .emailIcon, .fbIcon {
        width: 20px;
    }
    
    .fbIcon {
        width: 18px;
    }

    .phone {
        padding-top: 1px;
        height: 18px;
    }
}

@media only screen and (max-width: 700px) {

    .logo {
        width: 250px;
        margin-top: 30px; 
        padding-top: 17px;
        background-color: white;
        shape-outside: circle();
        clip-path: circle();   
        
    }

    .licensedIcon{
        width: 100px;
        
    }

    .licensedContainer{
        grid-column: 1/ span 1;
        grid-row: 1/span 1;

    }

    .linksContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: 24px;
        box-sizing: border-box;
        grid-column: 1 / span 5;
        grid-row: 2 / span 1;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.778);
        
    }

   .appHeader li > a {
        font-size: 1px;
        display: none;
        width: 1px;
    }
    
    .appHeader .navLine {
       display: none;

    }

    .icons {
        position: absolute;
        width: 90px;
        padding: 0;
        justify-self: flex-end;
        align-self: center;
        grid-column: 5 / span 1;
        grid-row: 2;
        display: flex;
        box-sizing: border-box;
    }
    
    .icons > li {
        list-style: none;
        margin-bottom: 0;
        margin-right: 10px;
    }
    
    .phone, .emailIcon, .fbIcon {
        width: 20px;
    }
    
    .fbIcon {
        width: 18px;
    }

    .phone {
        padding-top: 1px;
        height: 18px;
    }
}
