.nonHomepage {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    justify-items: center;
    margin: 70px 50px 0px 50px;
    padding-top: 120px;
    padding-bottom: 100px;
}

.pageHeading {
    justify-self: start;
}

.pageName {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
}

.pageSubname {
    font-size: 50px;
    font-weight: 600;
    color: #A20808;
    
}

.imgContainer {
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
   justify-self: center;
   align-self: center;
  
}

img {
    border-radius: 5px;
    width: 400px;
}


.formSection {
    grid-column: 1 / span 2;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 20px auto;
    row-gap: 30px;
    justify-items: center;
    height: 540px; 
    width: 1000px;
    margin-top: 50px;  
    background-color: #F0F0F2;
    border-radius: 5px;
    box-shadow: 5px 10px 18px #888888;
    padding: 24px;
}

.formSection p {
    grid-column: 1 /span 1;
    grid-row: 1 /span 1;
    justify-self: start;
}

.form {
    grid-column: 1 /span 1;
    grid-row: 2 /span 1;
    justify-self: center;
}

.form div:not(:first-of-type) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}

label {
    grid-column: 1 /span 1;
}

input, textarea {
    grid-row: 2 /span 1;
    grid-column: 1 / span 2;
    width: 300px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid gray;
}

textarea {
    width: 300px;
    max-width: 300px;
    min-width: 300px;
    height: 80px;
    max-height: 80px;
    min-height: 80px;
}

select {
    margin-left: 10px;
    margin-bottom: 20px;
}

.submit {
    background-color: #A20808;
    border: none;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    color: white;
    cursor: pointer;
}

.submit:hover {
    background-color: rgb(49, 48, 48);
}

.disabledSubmit {
    background-color: gray;
    border: none;
    width: 150px;
    height: 40px;
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    color: rgb(168, 167, 167);
}
.loading {
    position: relative;
    padding-left: 40px;
}
 .loading::before {
    content: '';
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-top: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
@keyframes spin {
    0% { transform: translateY(-50%) rotate(0deg); }
    100% { transform: translateY(-50%) rotate(360deg); }
}

.errors {
    grid-column: 2;
    color: red;
    font-size: 10px;
    height:5px;  
    align-self: center;
}

.errorInput {
    border: 1px solid red ;
}

        

@media only screen and (max-width: 1200px)  {

    .formSection {
        height: 520px; 
        width: 880px;
        margin-top: 50px;  
        background-color: #F0F0F2;
        border-radius: 5px;
        box-shadow: 5px 10px 18px #888888;
    }

    img {
        width: 370px;
    }
}



@media only screen and (max-width: 950px)  {
    .nonHomepage {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        justify-items: center;
        margin: 50px 50px 0 50px;
        padding-top: 120px;
        padding-bottom: 50px;
    }

    .pageName {
        font-size: 25px;
    }
    
    .pageSubname {
        font-size: 40px;        
    }

    .formSection {
        grid-column: 1 / span 1;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
        justify-items: center;
        height: 970px; 
        width: 600px;
        margin-top: 50px;  
        
    }
    .formSection p {
        grid-column: 1 /span 1;
        grid-row: 2 / span 1;
    }

    .form {
        grid-column: 1 /span 1;
        grid-row: 3 /span 1;
        justify-self: center;
    }

    .imgContainer {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        justify-self: center;
        align-self: center;
       
     }

     img {
        width: 400px;
    }

    input {
        width: 400px;
        height: 25px;
    }

    textarea {
        width: 400px;
        max-width: 400px;
        min-width: 400px;
        height: 80px;
        max-height: 150px;
        min-height: 80px;
    }
}

@media only screen and (max-width: 700px)  {

    .nonHomepage {
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: auto auto;
        justify-items: center;
        margin: 50px 20px 0 20px;
        padding-top: 120px;
        padding-bottom: 50px;
    }

    .pageHeading {
        justify-self: center;
        text-align: center;
    }

    .pageName {
        font-size: 20px;
    }
    
    .pageSubname {
        font-size: 35px;        
    }

    img {
        width: 250px;
    }

    .formSection {
        height: 800px; 
        width: 300px;
        margin-top: 50px;  
    }

    .form {
        padding-left: 20px;
        justify-self: start;
        align-self: center;
    }

    input {
        width: 250px;
        height: 25px;
    }

    textarea {
        width: 250px;
        max-width: 250px;
        min-width: 250px;
        height: 80px;
        max-height: 80px;
        min-height: 80px;
    }
}


@media only screen and (max-width: 450px)  {

    img {
        width: 210px;
    }

    .formSection {
        height: 800px; 
        width: 250px;
        margin-top: 50px;
        
    }

    .form {
        padding-left: 20px;
        align-self: flex-start;
    }

    input {
        width: 200px;
        height: 25px;
    }

    textarea {
        width: 200px;
        max-width: 200px;
        min-width: 200px;
        
    }
}