.nonHomepage {
    margin: 70px 0 0 0;
    padding-top: 120px;
    padding-bottom: 100px;
}

.pageHeading{
    margin: 0 50px ;
}

.pageName {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
}

.pageSubname {
    font-size: 50px;
    font-weight: 600;
    color: #A20808;
}

.serviceAreaSection {
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

}

.inServiceArea {
    justify-self: center;
    display: flex;
    gap: 50px;
    margin: 50px;
}

.inServiceArea p {
    font-weight: 300;
    margin-top: 10px;
}

.cityList {
    margin-top: 20px;
    margin-left: 40px;
}

.cityList li {
    list-style: circle;
    font-weight: 300;
    margin-bottom: 13px;
}

.outsideServiceArea{
    display: flex;
    width: 100%;
    height: 200px;
    background-color: #F0F0F2;
    justify-content: center;
    align-items: center;
}

.outsideServiceArea p {
    font-size: 22px;
    font-weight: 400;
}

.outsideServiceArea a {
    color: black;
}


@media only screen and (max-width: 950px)  {
    .pageName {
        font-size: 25px;
    }
    
    .pageSubname {
        font-size: 40px;
    }

    .inServiceArea {
        flex-direction: column;
    }

    .inServiceArea img {
        align-self: center;
    }

    .inServiceArea h3 {
        font-size: 24px;
    }

    .outsideServiceArea p {
        text-align: center;
        font-size: 20px;
        width: 60%;
        line-height: 30px;
    }
    
}

@media only screen and (max-width: 500px)  {

    .nonHomepage {
        margin: 40px 0 0 0;
        padding-bottom: 60px;
    }

    .pageHeading{
        margin: 0 20px ;
        text-align: center;
    }

    .pageName {
        font-size: 20px;
    }
    
    .pageSubname {
        font-size: 35px;
    }

    
}