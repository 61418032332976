@import "~react-image-gallery/styles/css/image-gallery.css";

.pageContainerOne, .pageContainerTwo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 300px 200px;
    margin: 70px 50px;
}

.pageContainerOne {
    background-image:url(../../assets/work/concretePump.jpeg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: 50%;  
    background-attachment: fixed;
}

.pageContainerTwo {
    background-image:url(../../assets/work/stampCrete.jpeg);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 50%;
    background-attachment: fixed;
}

.profilePhoto {
    border-radius: 50%;
    width: 200px;
}

.pageName {
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
}

.pageSubname {
    font-size: 50px;
    font-weight: 600;
    color: #A20808;
}

.pageHeading {
    margin: 60px 0 50px 50px
}

.commercialContainer {
    grid-column: 2;
    grid-row: 1 / span 2;
    background-color: white;
    height: 100%;
    padding: 0 0 50px 50px;
}

.residentialContainer {
    grid-column: 1;
    grid-row: 1 / span 2;
    background-color: white;
    height: 100%;
    padding: 0 40px 50px 0px;

}

.heading{
    display: flex;
    margin-bottom: 20px;
}

h3 {
    font-size: 35px;
    color: #645D73;
}

.infoIcon {
    padding-top: 4px;
    padding-left: 4px;
    width: 15px;
}

.tooltip >.tooltipContent{
    width: 300px;
    letter-spacing: 1px;
    line-height: 24px;
    font-size: 20px;
}


.description {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 24px;
    padding-bottom: 20px;
}

.list {
    list-style: circle;
    font-weight: 300;
    font-size: 20px;
    padding-left: 20px;
    line-height: 30px;
}

.list > li {
    list-style: square;
}

.whyUs {
    padding: 100px 100px;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 24px;
    background-color: #F0F0F2;
    text-align: center;
}

.whyUsHeader {
    margin-bottom: 20px;
    font-weight: 400;
    color: #A20808;
    text-align: center;
}

.nonHomepage {
    padding-top: 120px;
    padding-bottom: 150px;
}

.projectsContainer{
    margin: 10px 100px;
}

@media only screen and (max-width: 950px)  {

    .nonHomepage {
        padding-top: 120px;
        padding-bottom: 50px;
    }

    .pageName {
        font-size: 25px;
    }
    
    .pageSubname {
        font-size: 40px;
    }

    .pageContainerOne, .pageContainerTwo{
        grid-template-columns: auto;
        grid-template-rows: 300px auto;
        margin: 50px 30px;
        background-position: center;
        background-size: 80%;
        -webkit-background-size: 80%;
        -moz-background-size: 80%;
        -o-background-size: 80%;
    }

    .commercialContainer, .residentialContainer {
        grid-column: 1;
        grid-row: 2 / span 1;
        background-color: white;
        height: 100%;
        padding: 20px 0 0px 30px;
    }

    .description {
        font-size: 18px;
    }

    .list {
        font-size: 18px;
    }

    .whyUs {
        padding: 50px;
        margin-bottom: 30px;
        font-size: 18px;
    }

    h3 {
        font-size: 30px;
        color: #645D73;
        text-align: center;
    }

    .projectsContainer > .heading{
        display: block;
        margin-bottom: 10px;
    }

    .projectsContainer{
        margin: 10px 50px;
    }


}

@media only screen and (max-width: 650px)  {
    .pageContainerOne, .pageContainerTwo{
        grid-template-rows: 220px auto;
        margin: 50px 30px;
        background-position: center;
        background-size: 80%;
        -webkit-background-size: 80%;
        -moz-background-size: 80%;
        -o-background-size: 80%;
    }

}

@media only screen and (max-width: 450px)  {

    .pageHeading {
        margin: 40px 10px 0 10px;
        text-align: center;
    }

    .pageName {
        font-size: 20px;
    }
    
    .pageSubname {
        font-size: 35px;
    }

    .pageContainerOne, .pageContainerTwo {
        grid-template-columns: auto;
        grid-template-rows: 200px auto;
        margin: 50px 30px;
        background-attachment: scroll; 
        background-position:top;
        background-size: 250px;
        -webkit-background-size: 250px;
        -moz-background-size: 250px;
        -o-background-size: 250px;
    }

    .commercialContainer, .residentialContainer {
        grid-column: 1;
        grid-row: 2 / span 1;
        background-color: white;
        height: 100%;
        padding: 5px 0 0px 10px;
    }

    .whyUs {
        padding: 30px 20px;
        margin-bottom: 30px;
        font-size: 18px;
    }

    .projectsContainer{
        margin: 0 20px;
    }

    .projectsContainer > .heading{
        display: block;
        margin-bottom: -50px;
    }

}