@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;400&family=Poppins&family=Raleway:ital,wght@0,500;1,400&display=swap');

.homepage{
    display: grid;
    grid-template-rows: repeat(3, auto);
}

.intro {
    text-align: center;
    margin: 100px 100px 40px 100px;
    padding: 30px;
    font-size: 26px;
    font-weight: 300;
    color: #A60303;
    line-height: 36px;
    border: 4px solid black;
}

.welcome {
    font-size: 40px;
    font-weight: 600;
}

.serviceArea {
    font-weight: 400;
    text-decoration: underline;
}

/* About Section styling */
.aboutSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
    margin: 80px 0 50px 50px;
}

.selectionContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.familyOwnedLogo {
    width: 400px;
}

.aboutTitle {
    font-size: 30px;
}

.imageContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.familyOwned {
    width: 100%;   
}

.aboutSection .selectionContainer div{
    margin-top: 10px;
}

/* Services Sections Styling */
.servicesSection{
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    justify-items: center;
    margin: 40px 80px 0 80px;
}

.card1, .card2 {
    background-color: #F0F0F2;
    color: #333;
    width: 400px;
    height: 500px;
    border-radius: 15px;
    padding: 40px 50px;
    margin: 20px 30px;
    box-shadow: 5px 10px 18px #888888;
}
  
.sectionTitle {
    grid-column: 1 / span 2;
    grid-row: 1 / span 1;
    font-size: 45px;
    
}

.cardIcon{
    width: 100px;
    margin-bottom: 20px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px; 
}

h3 {
    font-size: 30px;
}

/* Contact Section styling */

.contactSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image:linear-gradient(rgba(131, 131, 131, 0.5), rgba(0, 0, 0, 0.5)), url(../../assets/work/concretePump.jpeg);
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: cover;
    position: relative;
    height:400px;
    margin: 50px;
    box-sizing: border-box;
}
.contactSection div:nth-of-type(2) {
    margin-top: 10px;
}

.contactText{
    font-size: 30px;
    color: white;
    text-align: center;
}



@media only screen and (max-width: 1275px)  {
    .card1, .card2 {
        width: 350px;
        height: 450px;
        padding: 10px 20px;
      }

      .servicesSection h3 {
        font-size: 25px;
    }

}

@media only screen and (max-width: 1200px)  {

    /* service section */
    .card1, .card2 {
        width: 300px;
        height: 400px;
        padding: 10px 20px;
      }

    .contactSection{
        margin: 50px;
    }

    /* about section */

    .aboutSection {
        margin: 50px 0 0 0;
    }
    .familyOwnedLogo {
        width: 300px;
    }
    .aboutTitle {
        font-size: 25px;
    }

    /* contact section */
    .contactText{
        font-size: 25px;
        color: white;
        
    }

}

@media only screen and (max-width: 960px) {
    .welcome {
        font-size: 30px;
        font-weight: 600;
    }
    
    .intro {
        font-size: 20px;
        text-align: center;
        margin: 50px 50px 25px 50px;
        padding: 30px;
    }

    .servicesSection{
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: repeat(3, auto);
        justify-items: center;
        margin: 40px 0;
    }
    

    .card1 {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }
      
    .card2 {
        grid-column: 1 / span 1;
        grid-row: 3 / span 1;
    }

    .card1, .card2 {
        justify-self: center;
    }
    
    .sectionTitle {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        font-size: 35px;
    }
    
    .cardIcon{
        width: 100px;
        margin-bottom: 20px;
    }
    
    .contentContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
    }
    

/* about section */

.aboutSection {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    justify-items: center;
    align-items: center;
    margin: 0;
}
.familyOwnedLogo {
    width: 300px;
}

.selectionContainer {
    height: 100%;
    justify-content: start;
    margin-top: 20px;
    grid-row: 2 / span 1;
}

.imageContainer {
    display: flex;
    width: 75%;
    justify-content: flex-end;
}

.aboutTitle {
    font-size: 20px;
}

/* contact section */

.contactSection{
    margin: 20px;
}

.contactSection {
    height:300px;
}
.contactText{
    font-size: 20px;
    color: white;
    
}
   
}

@media only screen and (max-width: 700px)  {
    .intro {
        font-size: 20px;
        text-align: center;
        margin: 40px 0 25px 0;
        padding: 10px;
        width: 85%;
        justify-self: center;
    }

    .welcome {
        font-size: 25px;
    }

    .intro > p {
        font-size: 18px;
        line-height: 25px;
        
    }
    .sectionTitle {
        font-size: 30px;
    }

    .servicesSection h3 {
        font-size: 18px;
    }

    .card1, .card2 {
        width: 200px;
        height: 300px;
        padding: 10px 10px;
        margin-top: 20px;
      }

    .familyOwnedLogo {
        width: 220px;
    }

    .imageContainer {
        display: flex;
        width: 90%;
        justify-content: flex-end;
    }

    .aboutTitle {
        font-size: 16px;
    }


    .contactSection{
        justify-self: center;
    }

    
}