@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Poppins&family=Raleway:ital,wght@0,500;1,400&display=swap');

.appHeader {
    font-family: 'Raleway', sans-serif;
    display: grid;
    grid-template-columns: auto 1fr 1fr 200px 60px;
    background-color: rgb(29, 28, 28);
    align-items: end;
    width: 100vw;
    position: fixed;
    z-index: 10;
    top: 0;
}

.imgContainer {
    grid-column: 1 / span 1;
    width: 200px;
}

.logoLink {
    justify-self: start; 
}

.logo {
    width: 100px;
    margin-left: 40px; 
    padding-top: 6px;
    background-color: white;
    shape-outside: circle();
    clip-path: circle();   
}

.icons {
    font-size: 24px;
    box-sizing: border-box;
    grid-column: 5 / span 1;
    align-self: start;
    width: 30px;
    height: 100px;
    padding-top: 12px;
    padding-right: 0px;
}

.icons > li {
    list-style: none;
}

.icons > li > div > a > img  {
   width: 25px;
}

.linksContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    flex-wrap: nowrap;
    gap: 10px;
    font-size: 24px;
    box-sizing: border-box;
    grid-column: 2 / span 2;
    height: 40px;
    margin-bottom: 10px;
    margin-left: 50px;
}

.linksContainer :hover {
    background-color: rgb(49, 48, 48);
}

li {
    list-style: none;
    white-space: nowrap;
}

li > a {
    padding: 14px 10px;
    color: white;
    text-decoration: none;
    font-style: normal;
}

.navLine {
    height: 35px;
    margin: 0;
}

.licensedContainer{
    grid-column: 4/ span 1;
    grid-row: 1;
    align-self: center;
}

.licensedIcon{
    width: 150px;
}

@media only screen and (max-width: 1200px)  {
    .linksContainer {
        font-size: 20px;
        margin-left: 0;
    }

    .appHeader {
        grid-template-columns: 100px auto auto auto 60px;
    }
}

@media only screen and (max-width: 950px)  {
    .appHeader {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .HamburgerMenuContainer {
        grid-column: 3 /span 1;
        grid-row: 1 / span 1;
        height: 40px;
        justify-self: flex-end;
        margin: 0 50px 5px 0;
        position: absolute;
        z-index: 10;
    }

    .imgContainer {
        grid-column: 2 / span 1;
        width: 200px;
        justify-self: center;
    }

    .linksContainer {
        grid-column: 3 /span 1;
        display: none;
        
    }

    .icons {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        justify-self: flex-start;
        width: 30px;
        height: 100px;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 750px)  {

    .HamburgerMenuContainer {
        margin: 0 10px 5px 0;
    }
    .logo {
        width: 100px;
        margin-left: 50px; 
        padding-top: 6px;
        background-color: white;
        shape-outside: circle();
        clip-path: circle();   
    }
}

@media only screen and (max-width: 400px)  {

    .HamburgerMenuContainer {
        margin: 0 8px 5px 0;
    }
}