@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Poppins&family=Raleway:ital,wght@0,500;1,400&display=swap');

.appFooter {
    font-family: 'Lexend Deca', sans-serif;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 160px;
    align-items: center;
    background-color: rgb(29, 28, 28);
    width: 100vw;
    height: 160px;
    margin-bottom: 0;
}

.imgContainer {
    grid-column: 4 / span 1;
    width: 150px;
    color: white;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-weight: 200;
    
}

.footerLinksContainer {
    display: flex;
    font-family: 'Raleway', sans-serif;
    flex-direction: column;
    font-size: 20px;
    font-weight: 400;
    box-sizing: border-box;
    grid-column: 2 / span 1;
    grid-row: 1;
    justify-self: center;
    width: 200px;
}

.contactUsContainer {
    grid-column: 1 / span 1;
    grid-row: 1;
    width: 400px;
    color: white;
    justify-self: center;
    
}

.phoneNumberContainer{
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 5px;
}

h1 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;
}


.logo {
    width: 100px;
    padding-top: 7px;
    background-color: white;
    shape-outside: circle();
    clip-path: circle();   
}

.rocNumber{
   width: 170px;
   text-align: left;
}


li {
    list-style: none;
}

.footerLinksContainer > li {
    margin-bottom: 12px;
}

li > a {
    color: white;
    text-decoration: none;
    font-style: normal;
}

.phone{
    width: 12px;
}

@media only screen and (max-width: 950px)  {
    .appFooter {
        font-family: 'Lexend Deca', sans-serif;
        display: grid;
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr;
        align-items: center;
        background-color: rgb(29, 28, 28);
        width: 100vw;
        height: 100%;
        margin-bottom: 0;
    }

    .imgContainer {
        grid-row: 3 / span 1;
        grid-column: 1 / span 1;
        width: 150px;
        color: white;
        align-self: center;
        justify-self: start;
        text-align: center;
        font-weight: 200;
        margin: 0 0 20px 20px;
    }
    
    .footerLinksContainer {
        display: flex;
        font-family: 'Raleway', sans-serif;
        flex-direction: column;
        font-size: 18px;
        font-weight: 400;
        box-sizing: border-box;
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
        justify-self: start;
        width: 200px;
        margin:10px 0 20px 10px;
    }

    .contactUsContainer {
        grid-column: 1 / span 1;
        grid-row: 1;
        width: 200px;
        color: white;
        justify-self: start;
        margin:20px 0 10px 20px;
        
    }

    .phoneNumberContainer:nth-of-type(3){
        font-size: 14px;
    }

}