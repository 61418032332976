.button {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-decoration: none;
    background-color: #A20808;
    border-radius: 5px;
    cursor: pointer;
    height: 30px;
    color: white;
    padding: 10px 25px;
    border: none;
}

.button:hover {
    background-color: rgb(49, 48, 48);   
}

.button:visited {
    color: white;  
}

.buttonContainer > button{
    height: 35px;
}

@media screen and (max-width: 700px) {
    .button {
        font-size: 12px;
        height: 20px;
        padding: 5px 15px;
    }
}