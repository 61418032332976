.HamburgerMenu {
    justify-self: center;
    position: absolute;
    z-index: 10;
}

.HamburgerMenu > img {
    width: 30px;
    height: 30px;

}

.linksContainer{
    position: absolute;
    background-color: rgba(0, 0, 0);
    color: white;
    grid-column: 5 / span 1;
    width: 200px;
    height: fit-content;
    top: 26px;
    right: -30px;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: 400;
    box-sizing: border-box;
    border-radius: 5px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    z-index: 10;
    border: 1px solid white;
}

.linksContainer > li:hover {
    background-color: rgb(49, 48, 48);
}

.linksContainer > li {
    height: 30px;
    list-style: none;
    border-bottom: 1px solid white;
    padding: 0 0 18px 10px;
}

.linksContainer li:last-of-type {
    border-bottom: none;
}

.linksContainer > li > a {
    display:block;
    width: 100%;
    font-size: 20px;
 
}


@media only screen and (min-width: 951px) {
    .HamburgerMenu {
        display: none;
    }

    .linksContainer {
        display: none;
    }
}


@media only screen and (max-width: 750px) {
    .HamburgerMenu {
        grid-column: 5 / span 1;
        width: 40px;
        right: 0;
    }

    .linksContainer {
        right: 10px;
    }
    
}